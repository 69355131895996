
.slider ,
.slider2 {
  width: 100%;
  overflow: hidden;
}
.slides, 
.slides2 {
  display: flex;
  transition: transform 1s ease-in-out;
}
.slide{
  margin: 0 10px;
  min-width:calc(100% /3 - 20px);
  box-sizing: border-box;
}
@media (max-width:767px) {
  .slide{
    margin: 0 10px;
    min-width:calc(100% - 20px);
    box-sizing: border-box;
  }
}


