.consult .contact-by .grid{
  width: 100%;
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

@media (max-width:767px) {
  .consult .contact-by .grid{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
}
.consult .radio input{
  display: none;
}
.consult .radio div.box{
  background-color: white;
  height:  50.86px; 
  width: 100%;
  font-size: 25px;
  font-weight: 400;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
@media (min-width:768px) and (max-width:959px) {
  .consult .contact-by .grid{
    width: 100%;
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
    gap: 30px;
  }
  .consult .radio div.box{
    font-size: 20px;
  }
}
@media (max-width:767px) {
  .consult .radio div.box{
    font-size: 10px;
    height: 27.93px;
    border-radius: 5px;
  }
}
@media (max-width:341px) {
  .consult .radio div.box{
    font-size: 8px;
  }
}
.consult .radio{
  width: 100%;
}
.consult .radio label{
  background-color: white;
  /* height: 85.86px;  */
  width: 100%;
  border-radius: 10px;
}

.consult .radio input:checked + label>div{
  background-color: var(--main-color);
  color: white;
}
/* ############################################################## */

.consult .your-info .section .box-row{
  display: flex;
}
@media (max-width:767px) {
  .consult .your-info .section .box-row{
    flex-direction: column;
  }
}
/* 22px  18px  51px*/
.consult .your-info .section .box-row .input{
  display: flex;
  flex-direction: column;
  width: 100%;
  color: black;
  margin: 13px 10px;
}
@media (max-width:767px) {
  .consult .your-info .section .box-row .input{
    margin: 13px 0px;
  }
}
.consult .your-info .section .box-row .input label{
  font-size: 22px;
  margin-bottom: 8px;
} 
@media (max-width:767px) {
  .consult .your-info .section .box-row .input label{
    font-size: 18px;
    margin-bottom: 8px;
  } 
  .consult .your-info .section .box-row .input{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
  }
}
.consult .your-info .section .box-row .input input{
  border-radius: 10px;
  outline: none;
  padding: 0 20px;
  height: 50px;
  width: 100%;
  font-size: 15px;
  color:black;
}
.consult .your-info .section .box-row .input .input-text{
  height: 54px;
}
@media (max-width:767px) {
  .consult .your-info .section .box-row .input input{
    height: 51px;
  }
}
.button-submit{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-submit .bs{
  width: 228px;
  height: 85px;
  border-radius: 50px;
  color: white;
  background-color: var(--main-color);
  font-size: 20px;
  font-weight: 400;
  border: none;
  outline: none;
  margin: 10px;
}
@media (max-width:767px) {
  .button-submit .bs{
    width: 200px;
  }
}
.focus:focus-within{
  border-color:#34C87C ;
}
.PhoneInputCountryIcon,
.PhoneInputCountryIcon--square,
.PhoneInputCountrySelect,
.PhoneInputCountrySelectArrow,
.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly],
.PhoneInputCountryIcon--border
{
  background-color: transparent;
  display:none;
  visibility: hidden;
  margin: 0;
}
.PhoneInputInput{
  width: 100%;
  left: 0;
  flex: 2;
  margin: 0;
  padding: 0px 2px !important;
  background-color: transparent;
}